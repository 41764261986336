<template>
    <div class="header_box">
        <van-icon name="arrow-left"  class="left_icon" @click="back"/>
          竞猜押宝喝彩礼包抽奖
    </div>
</template>
<script>
import Vue from "vue";
import { Icon } from "vant";
Vue.use(Icon);
export default {
  data() {
    return {};
  },
  methods: {
   // 返回上级页面
   back () {
     this.$router.go(-1)
   }
  },
};
</script>
<style lang="less" scoped>
.header_box{
    background: #F8F9FC;
    height: 44px;
    font-size: 18px;
    color: #0F2128;
    line-height: 44px;
    position: relative;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    .left_icon{
        position: absolute;
        left: 20px;
        top: 50%;
        font-size: 18px;
        color: #2B3340;
        margin-top: -9px;
    }

}
</style>
