<template>
  <div class="poster">
    <div class="poster_wrap">
      <div class="poster_box">
        <div class="info_box" ref="canvas" id="info_box">
          <img
            src="../../../assets/images/poster.png"
            alt=""
            class="poster_img"
          />
          <!-- <div class="lottery_level">{{ getUserInfo.name }}</div> -->
          <div class="lottery_name">{{ shareInfo.name }}</div>
          <div class="lottery_box">
            <img :src="shareInfo.images" alt="" class="lottery_img" />
          </div>
        </div>
      </div>
      <div class="poster_canvas" v-if="showPoster">
        <div class="canvas_img">
          <img :src="imgUrl" alt="" />
        </div>
        <img
          src="../../../assets/images/close.png"
          alt=""
          class="close_icon"
          @click="closePoster"
        />
        <div class="tips_text">长按可保存图片</div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Toast } from "vant";
import html2canvas from "html2canvas";
import { mapGetters } from "vuex";
Vue.use(Toast);
export default {
  props: {
    shareInfo: {
      type: Object,
      default: () => {
        return { level: {} };
      },
    },
  },
  data() {
    return {
      imgUrl: "",
      showPoster: false,
    };
  },
  created() {
    console.log(this.shareInfo.image);
  },
  mounted() {
    console.log(this.shareInfo);
    Toast({
      message: "海报生成中...",
      duration: 0,
      onClose: () => {
        // this.htmlToCanvas();
      },
    });
    let that = this;
    setTimeout(() => {
      that.htmlToCanvas();
    }, 2000);
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  methods: {
    // 关闭分享海报
    closePoster() {
      this.$emit("closePoster", false);
    },
    htmlToCanvas() {
      html2canvas(this.$refs.canvas, {
        scrollY: 0,
        scrollX: 0,
        height: document.getElementById("info_box").clientHeight - 2,
        // backgroundColor:"rgba(0,0,0,0)"
      }).then((canvas) => {
        let imageUrl = canvas.toDataURL("image/png");
        this.imgUrl = imageUrl;
        this.showPoster = true;
        Toast.clear();
        // setTimeout(() => {
        // }, 0);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.poster {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  //   padding: 30px 0;
  .poster_wrap {
    width: 95.73%;
  }
  .poster_canvas {
    width: 100%;
    height: auto;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    .tips_text {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -20px;
      font-size: 12px;
      color: #ffffff;
    }
    .canvas_img {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
    .close_icon {
      width: 20px;
      height: 20px;
      top: 10px;
      right: 10px;
      position: absolute;
      z-index: 99999;
    }
  }
  .poster_box {
    position: absolute;
    top: -10000px;
    // top: 0;
    left: 0;
    width: 100%;
    // height: 86.58%;
    height: auto;
    .close_icon {
      width: 20px;
      height: 20px;
      top: 10px;
      right: 10px;
      position: absolute;
      z-index: 99999;
    }
    .canvas_img {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
    .info_box {
      width: 100%;
      //   height: 100%;
      position: relative;
      //   opacity: 0;
      .poster_img {
        width: 100%;
        // height: 100%;
        height: auto;
        display: block;
      }
      .lottery_level {
        // width: 80px;
        width: 23%;
        position: absolute;
        //   top: 163px;
        top: 28.6%;
        font-size: 14px;
        color: #e96346;
        // left: 109px;
        left: 30.4%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.3%;
        font-weight: 600;
        text-transform: uppercase;
        text-shadow: 0 0 9px rgba(233, 132, 97, 0.6);
      }
      .lottery_name {
        // width: 80px;
        position: absolute;
        // top: 188px;
        top: 33.3%;
        font-size: 14px;
        color: #ffffff;
        // left: 150px;
        left: 34%;
        width: 31%;
        height: 2.3%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.3%;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-shadow: 0 0 9px rgba(233, 132, 97, 1);
      }

      .lottery_box {
        position: absolute;
        // left: 32.6%;
        left: 0;
        // top: 38.3%;
        top: 40%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .close_icon {
    width: 40px;
    height: 40px;
    top: 10px;
    right: 10px;
    position: absolute;
  }
  .lottery_level {
    font-size: 20px !important;
  }
  .lottery_name {
    font-size: 20px !important;
  }
  .lottery_box {
    // width: 334px;
    width: 100%;
    height: 284px;
    border-radius: 50%;
    overflow: hidden;
  }
  .lottery_img {
     display: block;
    width: 100%;
    height: 100%;
    width: auto;
    height: auto;
    max-width: 272px;
    max-height: 272px;
  }
}
@media screen and (max-width: 768px) {
  .lottery_box {
    // width: 250px;
    width: 100%;
    height: 212px;
    border-radius: 50%;
    overflow: hidden;
  }
  .lottery_img {
    display: block;
    width: 100%;
    height: 100%;
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 200px;
  }
}
@media screen and (max-width: 414px) {
  .lottery_level {
    font-size: 15px !important;
  }
  .lottery_name {
    font-size: 15px !important;
  }
  .lottery_box {
    // width: 138px;
    width: 100%;
    height: 112px;
    border-radius: 50%;
    overflow: hidden;
  }
  .lottery_img {
    display: block;
    width: 100%;
    height: 100%;
    width: auto;
    height: auto;
    max-width: 106px;
    max-height: 106px;
  }
}
@media screen and (max-width: 375px) {
  .lottery_level {
    font-size: 14px !important;
  }
  .lottery_name {
    font-size: 14px !important;
  }
  .lottery_box {
    // width: 126px;
    width: 100%;
    height: 105px;
    border-radius: 50%;
    overflow: hidden;
  }
  .lottery_img {
     display: block;
    width: 100%;
    height: 100%;
    width: auto;
    height: auto;
    max-width: 98px;
    max-height: 98px;
  }
}
@media screen and (max-width: 360px) {
  .lottery_level {
    font-size: 14px !important;
  }
  .lottery_name {
    font-size: 14px !important;
  }
  .lottery_box {
    // width: 120px;
    width: 100%;
    // height: 120px;
    height: 100px;
    // border-radius: 50%;
    overflow: hidden;
  }
  .lottery_img {
    display: block;
    width: 100%;
    height: 100%;
    width: auto;
    height: auto;
    max-width: 96px;
    max-height: 96px;
  }
}
@media screen and (max-width: 320px) {
  .lottery_level {
    font-size: 12px !important;
  }
  .lottery_name {
    font-size: 12px !important;
  }
  .lottery_box {
    // width: 108px;
    width: 100%;
    // height: 107px;
    height: 90px;
    // border-radius: 50%;
    overflow: hidden;
  }
  .lottery_img {
    display: block;
    width: 100%;
    height: 100%;
    width: auto;
    height: auto;
    max-width: 86px;
    max-height: 86px;
  }
}
</style>
